import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSettings = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/user/settings`,
        {
            params,
            cancelToken,
        },
    )

export const updateSetting = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/user/settings`,
        data,
        {
            params,
            cancelToken,
        },
    )
